import React from 'react';

import Page from 'components/Page';
import ContentSection from 'components/ContentSection';
import SectionIntro from 'components/SectionIntro';
import Contact from 'components/Contact';

const metaTags = {
  title: 'webkid - contact',
  author: 'Webkid',
  twitterUsername: '@wbkd',
  description: 'Get in touch!',
  siteUrl: 'https://webkid.io/contact/',
  robots: 'index,follow',
};

const ContactPage = () => {
  return (
    <Page metaTags={metaTags} showBanner>
      <ContentSection centered>
        <SectionIntro
          title="Contact Us"
          text="Are you interested in a collaboration? We are looking forward to hear about your project. Get in touch!"
        />
        <Contact />
      </ContentSection>
    </Page>
  );
};

export default ContactPage;
